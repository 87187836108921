import React, { useContext } from 'react';
import './styles.scss';
import { AppBar, Toolbar, Button, Typography } from '@material-ui/core';
import DialogModal from '../Dialog/DialogModal';
import apiTokenForm from '../../data/apiTokenForm';
import help from '../../data/help';
import legalNotice from '../../data/legalNotice.json';
import Loading from '../loading/Loading';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../contexts/DataContextProvider';
import Logo from '../Logo/Logo';

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: 'linear-gradient(to bottom, rgba(0, 26, 40, 1) 0%, rgba(18, 54, 71, 1) 100%)',
    },
    iconButton: {
        padding: theme.spacing(1),
    },
    iconButtonMobile: {
        padding: theme.spacing(1.5),
    },
    title: {
        textTransform: 'uppercase',
        color: '#fff',
        fontWeight: 'bold',
    },
    bit: {
        color: theme.palette.secondary.light,
        textTransform: 'uppercase',
        fontStyle: 'italic',
    },
    button: {
        textAlign: 'left',
        flexGrow: 1,
        justifyContent: 'left',
    },
    toolBar: {
        padding: theme.spacing(0, 0.75),
    },
}));

const Header = () => {
    const classes = useStyles();
    const { refreshData } = useContext(DataContext);

    const handleClick = () => {
        refreshData();
    };
    return (
        <>
            <header>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        <div className={classes.button}>
                            <Button onClick={handleClick}>
                                <Logo minWidth={150} />
                            </Button>
                        </div>
                        <DialogModal data={apiTokenForm}></DialogModal>
                        <DialogModal data={help}></DialogModal>
                        <DialogModal data={legalNotice}></DialogModal>
                    </Toolbar>
                </AppBar>
            </header>
            <Loading />
        </>
    );
};

export default Header;
