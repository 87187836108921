import React, { useState, createContext, useEffect } from 'react';
// import cryptoCoinMap from '../config/cryptocoinMap.json';

// Server side initialization of props from Bitpanda
export const DataContext = createContext();

const DataContextProvider = (props) => {
    const url = 'loadApi.php';

    // Data Arrays filled by functions
    let count = [];
    let apiDataArray = {};
    let dataJsonArray = [];
    let dataSummaryJsonArray = [];

    // States
    const [countLoadingMax, seCountLoadingMax] = useState(6);
    const [countLoading, setCountLoading] = useState(0);
    const [applicationData, setApplicationData] = useState(false);
    const [dataLoad, setDataLoad] = useState(true);

    // Get Data from Bitpanda Api. Use loadApi.php
    useEffect(() => {
        // Steps to finish loading of data. Check all Endpoints to load.
        let apiDataOptions = {
            apiKey: atob(localStorage.getItem('apiToken')),
            endpoints: ['wallets', 'wallets/transactions', 'fiatwallets', 'fiatwallets/transactions', 'trades', 'ticker'],
        };

        seCountLoadingMax(apiDataOptions.endpoints.length);

        const xmlRequest = (item, i) => {
            const xmlhttp = new XMLHttpRequest();
            xmlhttp.open('GET', url, true);
            xmlhttp.setRequestHeader('X-Api-Key', apiDataOptions.apiKey);
            xmlhttp.setRequestHeader('Endpoint', apiDataOptions.endpoints[i]);
            xmlhttp.send();

            xmlhttp.onreadystatechange = () => {
                try {
                    if (xmlhttp.readyState === XMLHttpRequest.DONE) {
                        // console.log(xmlhttp.readyState, xmlhttp.status, XMLHttpRequest.DONE, item);
                        if (xmlhttp.status === 0 || (xmlhttp.status >= 200 && xmlhttp.status < 400)) {
                            apiDataArray[item] = JSON.parse(xmlhttp.responseText);
                            count.push(item);

                            // Set loading state
                            setCountLoading(count.length);

                            if (apiDataOptions.endpoints.length === count.length) {
                                dataJsonFactory(apiDataArray);
                                dataSummaryJsonFactory(dataJsonArray);
                                // console.log('### -----', apiDataArray, dataJsonArray);
                                setApplicationData({ wallets: dataJsonArray, summary: dataSummaryJsonArray });
                                setDataLoad(false);
                                // console.log('dataJsonArray', dataJsonArray);
                            }
                        } else {
                            count = [];
                            console.log('Oh no! There has been an error with the request!', item);
                        }
                    }
                } catch (e) {
                    setDataLoad(false);
                    console.log('No Data was load. Please Enter valid Api Token.');
                }
            };
        };

        dataLoad &&
            apiDataOptions.endpoints.map((item, i) => {
                xmlRequest(item, i);
            });
    }, [dataLoad]);

    // ########## CALCULATIONS

    const refreshData = () => {
        setDataLoad(true);
    };

    const getFiatSymbol = (trades, fiatWallets, walletId) => {
        let fiatSymbol = 'USD';
        trades.map((itemA) => {
            if (itemA.attributes.wallet_id === walletId) {
                fiatWallets.map((itemB) => {
                    if (itemA.attributes.fiat_id === itemB.attributes.fiat_id) {
                        fiatSymbol = itemB.attributes.fiat_symbol;
                    }
                });
            }
        });

        return fiatSymbol;
    };

    // Calculate the invest for given Wallet in dataJsonFactory.
    // Run all Trades and compare with current Wallet ID.
    // If Buy add if Sell remove amoind_fiat
    const calcInvest = (trades, walletId) => {
        let walletInvest = 0;
        for (let i = trades.length - 1; i >= 0; i--) {
            const item = trades[i];
            if (item.attributes.wallet_id === walletId) {
                console.log(item);
                console.log(item.attributes.wallet_id, walletInvest, item.attributes.type, parseFloat(item.attributes.amount_fiat));
                console.log('item.attributes.type', item.attributes.type, item.attributes.wallet_id, parseFloat(item.attributes.amount_fiat));
                if (item.attributes.type === 'buy') {
                    // console.log(item.attributes.wallet_id, 'A', parseFloat(walletInvest), '+', parseFloat(item.attributes.amount_fiat), walletInvest);
                    walletInvest = parseFloat(walletInvest) + parseFloat(item.attributes.amount_fiat);
                } else {
                    if (parseFloat(walletInvest) - parseFloat(item.attributes.amount_fiat) < 0) {
                        walletInvest = 0;
                    } else {
                        walletInvest = parseFloat(walletInvest) - parseFloat(item.attributes.amount_fiat);
                    }
                }
            }
        }
        return walletInvest;
    };

    // Calculate the value for given Wallet in dataJsonFactory
    const calcValue = (ticker, balance) => {
        return ticker * balance;
    };

    // Calculate the Profit in percent
    const calcProfit = (invest, value) => {
        return value - invest;
    };

    // Calculate the Profit in percent
    const calcProfitPCT = (invest, value) => {
        return (100 / invest) * value - 100;
    };

    // Calculate the Price Average
    const calcPriceAvereage = (invest, balance) => {
        return invest / balance;
    };

    // Calculate Wallet JSON Data for use in Application
    const dataSummaryJsonFactory = (props) => {
        console.log('dataSummaryJsonFactory', props);
        let invest = 0;
        let value = 0;
        let profit = 0;
        let profitPCT = 0;
        let fiatShort = 'USD';
        const chartData = localStorage.getItem('chart') ? JSON.parse(localStorage.getItem('chart')) : [];

        props.map((item) => {
            invest = invest + item.invest;
            value = value + item.value;
            profit = profit + item.profit;
            profitPCT = (100 / invest) * profit;
            fiatShort = item.fiatShort;
        });

        //  ProfitSummaryChart from localStorage
        chartData.push([new Date().getTime(), parseFloat(profit.toFixed(2))]);
        localStorage.setItem('chart', JSON.stringify(chartData));

        const walletsSummary = {
            invest: invest,
            value: value,
            profit: profit,
            profitPCT: profitPCT,
            fiatShort: fiatShort,
            chart: chartData,
        };

        dataSummaryJsonArray = walletsSummary;
    };

    const dataJsonFactory = (props) => {
        console.log('dataJsonFactory Props', props);
        if (props.wallets.data) {
            props.wallets.data.map((item) => {
                if (item.attributes.balance > 0) {
                    console.log('dataJsonFactory Item', item);

                    // Call calculations
                    const fiatSymbol = getFiatSymbol(props.trades.data, props.fiatwallets.data, item.id);
                    const balance = parseFloat(item.attributes.balance);
                    const price = parseFloat(props.ticker[item.attributes.cryptocoin_symbol][fiatSymbol]);
                    const invest = calcInvest(props.trades.data, item.id);
                    const value = calcValue(price, balance);
                    const profit = calcProfit(invest, value);
                    const profitPCT = calcProfitPCT(invest, value);
                    const priceAverage = calcPriceAvereage(invest, balance);

                    // Wallet Object
                    const wallet = {
                        name: item.attributes.name,
                        cryptoShort: item.attributes.cryptocoin_symbol,
                        fiatShort: fiatSymbol,
                        balance: balance,
                        value: value,
                        invest: invest,
                        profit: profit,
                        profitPCT: profitPCT,
                        price: price,
                        priceAverage: priceAverage,
                    };

                    dataJsonArray.push(wallet);
                }
            });
        } else {
            console.log(' OH Something is wrong', 'applecationData', applicationData);
            setDataLoad(false);
            localStorage.removeItem('apiToken');
        }
    };

    return (
        <DataContext.Provider value={{ applicationData, countLoading, countLoadingMax, setDataLoad, dataLoad, refreshData }}>
            {props.children}
        </DataContext.Provider>
    );
};

export default DataContextProvider;
