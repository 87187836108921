import React, { useContext, Fragment, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/DataContextProvider';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    progress: {
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    spinner: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginLeft: -20,
        marginTop: -20,
        zIndex: 1,
    },
}));

const Loading = () => {
    const classes = useStyles();
    const { countLoading, countLoadingMax } = useContext(DataContext);
    const [completed, setCompleted] = useState(0);
    const splitComplete = 100 / countLoadingMax;
    // console.log('### countLoading', countLoading, splitComplete, completed);

    useEffect(() => {
        function progress() {
            setCompleted((oldCompleted) => {
                if (oldCompleted === 100) {
                    return 0;
                }
                const diff = countLoading * splitComplete;
                return Math.min(diff, 100);
            });
        }

        progress();
    }, [countLoading]);

    return (
        <Fragment>
            <div className={classes.progress}>
                {countLoading !== countLoadingMax && <LinearProgress variant="determinate" value={completed} color="secondary" />}
            </div>
            {countLoading !== countLoadingMax && (
                <div className={classes.spinner}>
                    <CircularProgress color="secondary" />
                </div>
            )}
        </Fragment>
    );
};

export default Loading;
