import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class PerformancePiChart extends Component {
    render() {
        const chartConfig = {
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
            },
            title: {
                text: 'Performance',
                style: {
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                },
            },
            subtitle: {
                text: 'Return Performance by currency.',
                style: {
                    color: '#999999',
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    borderColor: 'transparent',
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.1f}%',
                        style: {
                            color: '#c1fcfa',
                            fontSize: '15px',
                            textOutline: false,
                        },
                    },
                },
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
            },

            series: [
                {
                    name: 'Crypto Currencies',
                    colorByPoint: true,
                    data: this.props.chartWalletPerformancePiData,
                },
            ],
        };
        return (
            <div className="card">
                <HighchartsReact highcharts={Highcharts} options={chartConfig} />
            </div>
        );
    }
}

export default PerformancePiChart;
