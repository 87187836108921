import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logoImage from '../../bitdash-logo.svg';

const Logo = ({ minWidth }) => {
    //Styles
    const useStyles = makeStyles({
        img: { height: 'auto', maxWidth: '100%', minWidth: minWidth },
    });
    const classes = useStyles();

    // Render
    return <img className={classes.img} src={logoImage} alt="BITDASHED" />;
};

export default Logo;
