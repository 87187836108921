import React, { useRef, useContext, useState } from 'react';
import { Typography, Paper, Button } from '@material-ui/core';
import { DataContext } from '../../contexts/DataContextProvider';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../Logo/Logo';
import currencySign from '../../helpers/currencySign/currencySign';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 0.5),
        position: 'sticky',
        zIndex: 10,
        top: 0,
    },
    root2: {
        padding: theme.spacing(1),
        paddingBottom: 0,
        position: 'sticky',
        zIndex: 10,
        top: 0,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#c1fcfa',
        background: 'linear-gradient(180deg, #001a28 0, #123647)',
        border: '1px dashed rgba(193,252,250,0.75)',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    values: {
        fontSize: '0.75rem',
        color: '#c1fcfa',
    },
    minus: {
        color: '#cc0000',
    },
    plus: {
        color: '#adff2f',
    },
    item: {
        padding: theme.spacing(0, 1),
        display: 'inline-block',
    },
    button: {
        background: 'rgba(0, 0, 0, 1)',
    },
}));

const WalletsSummary = () => {
    const classes = useStyles();
    const { applicationData } = useContext(DataContext);
    const root = useRef(null);
    const [displayLogo, setDisplayLogo] = useState(false);
    // console.log('WalletsSummary', applicationData && applicationData);

    window.onscroll = () => {
        if (root.current.offsetTop > root.current.offsetHeight) {
            displayLogo === false && setDisplayLogo(true);
        } else {
            setDisplayLogo(false);
        }
    };

    const { refreshData } = useContext(DataContext);
    const handleClick = () => {
        refreshData();
    };

    return (
        <>
            {applicationData && (
                <div className={displayLogo ? classes.root : classes.root2} ref={root}>
                    {displayLogo && (
                        <div className={classes.button}>
                            <Button onClick={handleClick}>
                                <Logo minWidth={80} />
                            </Button>
                        </div>
                    )}
                    <Paper className={classes.paper}>
                        <div className={classes.item}>
                            <Typography variant="overline" color="secondary">
                                invest
                            </Typography>
                            <Typography variant="body2" className={classes.values}>
                                {applicationData.summary.invest.toFixed(2)} {currencySign(applicationData.summary.fiatShort)}
                            </Typography>
                        </div>
                        <div className={classes.item}>
                            <Typography variant="overline" color="secondary">
                                value
                            </Typography>
                            <Typography variant="body2" className={classes.values}>
                                {applicationData.summary.value.toFixed(2)} {currencySign(applicationData.summary.fiatShort)}
                            </Typography>
                        </div>
                        <div className={classes.item}>
                            <Typography variant="overline" color="secondary">
                                profit
                            </Typography>
                            <Typography variant="body2" className={classes.values}>
                                <span className={classes.values}>
                                    {applicationData.summary.profit.toFixed(2)} {currencySign(applicationData.summary.fiatShort)}
                                </span>{' '}
                                <span className={applicationData.summary.profit.toFixed(2) >= 0 ? classes.plus : classes.minus}>
                                    ({applicationData.summary.profitPCT.toFixed(2)}%)
                                </span>
                            </Typography>
                        </div>
                    </Paper>
                </div>
            )}
        </>
    );
};

export default WalletsSummary;
