import React, { Fragment } from 'react';
import { TwitterDMButton } from 'react-twitter-embed';
import './styles.scss';
import DialogModal from '../Dialog/DialogModal';
import help from '../../data/help';
import legalNotice from '../../data/legalNotice';
import { makeStyles } from '@material-ui/core/styles';
import XrpTipBot from '../xrpTipBot/XrpTipBot';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#fff',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '.5rem',
        zIndex: 10,
        borderTop: '1px dashed #c1fcfa',
        background: 'linear-gradient(180deg,#001a28 0,#123647)',
    },
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <footer className={classes.root}>
            {/* <DialogModal data={help}></DialogModal> */}
            {/* <DialogModal data={legalNotice}></DialogModal> */}
            <XrpTipBot />
            <div className="twitter-float">
                <TwitterDMButton id={453867892} options={{ text: 'CRYPTO-CALC: ' }} />{' '}
            </div>
        </footer>
    );
};

export default Footer;
