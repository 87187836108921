import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DataContext } from '../../contexts/DataContextProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}));

const ProfitSummaryChart = () => {
    const { applicationData } = useContext(DataContext);
    const classes = useStyles();
    const handleClick = () => {
        localStorage.removeItem('chart');
        window.location.reload();
    };

    const chartConfig = {
        chart: {
            animation: true,
            type: 'line',
            backgroundColor: 'transparent',
        },

        credits: {
            enabled: false,
        },
        title: {
            text: 'Local Profit History',
            style: {
                color: '#FFFFFF',
                fontWeight: 'bold',
            },
        },
        subtitle: {
            text: 'This history is stored in your browser.',
            style: {
                color: '#999999',
            },
        },
        navigator: {
            enabled: false,
        },
        scrollbar: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            labels: {
                style: { color: '#c1fcfa', cursor: 'default', fontSize: '14px' },
            },
        },
        yAxis: [
            {
                title: {
                    text: 'Profit',
                    style: {
                        color: '#999999',
                    },
                },
                labels: {
                    style: { color: '#c1fcfa', cursor: 'default', fontSize: '14px' },
                },
            },
        ],
        legend: {
            shadow: false,
            itemStyle: {
                color: '#FFFFFF',
                fontWeight: 'bold',
            },
            itemHoverStyle: {
                color: '#999999',
                fontWeight: 'bold',
            },
        },
        tooltip: {
            shared: true,
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
            },
        },
        series: [
            {
                type: 'area',
                name: 'Profit',
                // data: this.state.chartWalletProfitSumData,
                data: applicationData && applicationData.summary.chart,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, 'rgba(0, 255, 0, 0.5)'],
                        [1, 'rgba(0, 255, 0, 0.9)'],
                    ],
                },
                negativeFillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, 'rgba(255, 0, 0, 0.5)'],
                        [1, 'rgba(255, 0, 0, 0.9)'],
                    ],
                },
            },
        ],
    };

    return (
        <>
            {applicationData && (
                <div className={classes.root}>
                    <div className="content-item">
                        <button className="resetChart" onClick={handleClick}>
                            Reset
                        </button>
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={chartConfig} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProfitSummaryChart;
