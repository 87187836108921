import React, { useContext } from 'react';
import { DataContext } from '../../contexts/DataContextProvider';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid } from '@material-ui/core';

const InvestChart = () => {
    const { applicationData } = useContext(DataContext);
    // console.log('##  Chart', applicationData);
    let chartWalletNames = [];
    let chartWalletInvest = [];
    let chartWalletRendite = [];
    let chartWalletColor = [];
    let nIndex = 0;

    applicationData &&
        applicationData.wallets.map((item, index) => {
            const { name, price, balance, priceAverage, invest, profit, value, profitPCT, cryptoShort, fiatShort } = item;
            // console.log(chartWalletColor);
            chartWalletNames.push(cryptoShort);
            chartWalletInvest.push(invest);
            chartWalletRendite.push({ y: value, color: value >= invest ? '#adff2f' : '#ff0000' });
        });

    const chartConfig = {
        chart: {
            animation: true,
            type: 'column',
            backgroundColor: 'transparent',
        },

        credits: {
            enabled: false,
        },
        title: {
            text: 'Invest & Profit',
            style: {
                color: '#FFFFFF',
                fontWeight: 'bold',
            },
        },
        navigator: {
            enabled: false,
        },
        scrollbar: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        xAxis: [
            {
                title: 'currencies',
                type: 'categories',
                categories: chartWalletNames,
                labels: {
                    style: { color: '#c1fcfa', cursor: 'default', fontSize: '14px' },
                },
            },
        ],
        yAxis: [
            {
                title: {
                    text: 'Invest & Profit',
                    style: {
                        color: '#999999',
                    },
                },
                min: 0,
            },
            {
                min: 0,
                title: {
                    text: 'Invest & Profit',
                    style: {
                        color: '#999999',
                        display: 'none',
                    },
                },
                labels: {
                    style: { color: '#c1fcfa', cursor: 'default', fontSize: '14px' },
                },
                opposite: true,
            },
        ],
        legend: {
            shadow: false,
            itemStyle: {
                color: '#FFFFFF',
                fontWeight: 'bold',
            },
            itemHoverStyle: {
                color: '#999999',
                fontWeight: 'bold',
            },
        },
        tooltip: {
            shared: true,
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
            },
        },
        series: [
            {
                name: 'Invest',
                color: 'rgba(193,252,250,.5)',
                data: chartWalletInvest,
                tooltip: {
                    // valuePrefix: "$",
                    valueSuffix: ' €',
                },
                pointPadding: 0.3,
                pointPlacement: 0,
                yAxis: 1,
            },
            {
                name: 'Value',
                color: '#adff2f',
                data: chartWalletRendite,
                tooltip: {
                    // valuePrefix: "$",
                    valueSuffix: ' €',
                },
                pointPadding: 0.4,
                pointPlacement: 0,
                yAxis: 1,
            },
        ],
    };

    return (
        <div className="content-area ">
            <div className="content-item">
                <div className="card">
                    <HighchartsReact highcharts={Highcharts} options={applicationData && chartConfig} />
                </div>
            </div>
        </div>
    );
};
export default InvestChart;
