import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class ProfitChart extends Component {
    render() {
        const chartConfig = {
            chart: {
                animation: true,
                type: 'column',
                backgroundColor: 'transparent',
            },

            credits: {
                enabled: false,
            },
            title: {
                text: 'Invest & Profit',
                style: {
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                },
            },
            navigator: {
                enabled: false,
            },
            scrollbar: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false,
            },
            xAxis: [
                {
                    title: 'currencies',
                    type: 'categories',
                    categories: this.props.chartWalletNames,
                    labels: {
                        style: { color: '#c1fcfa', cursor: 'default', fontSize: '14px' },
                    },
                },
            ],
            yAxis: [
                {
                    title: {
                        text: 'Invest & Profit',
                        style: {
                            color: '#999999',
                        },
                    },
                    min: 0,
                },
                {
                    min: 0,
                    title: {
                        text: 'Invest & Profit',
                        style: {
                            color: '#999999',
                            display: 'none',
                        },
                    },
                    labels: {
                        style: { color: '#c1fcfa', cursor: 'default', fontSize: '14px' },
                    },
                    opposite: true,
                },
            ],
            legend: {
                shadow: false,
                itemStyle: {
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                },
                itemHoverStyle: {
                    color: '#999999',
                    fontWeight: 'bold',
                },
            },
            tooltip: {
                shared: true,
            },
            plotOptions: {
                column: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    name: 'Invest',
                    color: 'rgba(193,252,250,.5)',
                    data: this.props.chartWalletInvest,
                    tooltip: {
                        // valuePrefix: "$",
                        valueSuffix: ' €',
                    },
                    pointPadding: 0.3,
                    pointPlacement: 0,
                    yAxis: 1,
                },
                {
                    name: 'Value',
                    color: 'rgba(186,60,61,.9)',
                    data: this.props.chartWalletRendite,
                    tooltip: {
                        // valuePrefix: "$",
                        valueSuffix: ' €',
                    },
                    pointPadding: 0.4,
                    pointPlacement: 0,
                    yAxis: 1,
                },
            ],
        };
        return (
            <div className="content-area ">
                <div className="content-item">
                    <div className="card">
                        <HighchartsReact highcharts={Highcharts} options={chartConfig} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfitChart;
