import currencyList from './currencyList.json';

const currencySign = (props) => {
    let currency = props;

    currencyList.map((item) => {
        if (item.name === props) {
            currency = item.codePoint;
        }
        return false;
    });
    return currency;
};

export default currencySign;
