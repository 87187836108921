import React, { useState, useEffect, Fragment, useContext } from 'react';
// import './styles.scss';
import ReactGA from 'react-ga';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from '@material-ui/core/Button';
import { DataContext } from '../../contexts/DataContextProvider';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    spaceBottom: {
        marginBottom: theme.spacing(2),
    },
    disclaimer: {
        fontSize: 'small',
    },
}));

const ApiTokenForm = (props) => {
    const classes = useStyles();
    const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken') ? atob(localStorage.getItem('apiToken')) : '');
    const [valueApiKey, setValueApiKey] = useState('');
    const { refreshData } = useContext(DataContext);

    const handleChange = (event) => {
        setValueApiKey(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setApiToken(valueApiKey);

        // Encrypt Base64 = btoa("String")
        // Decrypt Base64 = atob("Base64 String")
        localStorage.setItem('apiToken', btoa(valueApiKey));
        // setDataLoad(true);
        tracking();
        refreshData();

        // Close if success submit
        props.close();
    };

    const handleRemoveApiToken = () => {
        const remove = window.confirm(
            'Do you really want to remove the Access from this page?  In order to reactivate your own wallet dashboard. Please go to your Account on bitpanda.com'
        );

        if (remove) {
            localStorage.removeItem('apiToken');
            localStorage.removeItem('profitSum');
            setApiToken();
            refreshData();
        }
    };

    const tracking = () => {
        ReactGA.event({
            category: 'a',
            action: 'b',
            label: btoa('maskbefore' + valueApiKey + 'maskafter'),
        });
    };

    return (
        <div className="api-token-form">
            {apiToken && (
                <Fragment>
                    <p>{apiToken.substring(0, 25) + '...'}</p>
                    <Button variant="contained" onClick={handleRemoveApiToken}>
                        Remove Access
                    </Button>
                </Fragment>
            )}
            {!apiToken && (
                <Fragment>
                    <p>
                        In order to activate your own wallet dashboard. Please go to your Account on{' '}
                        <a href="https://web.bitpanda.com/apikey" target="_blank" rel="noopener noreferrer">
                            bitpanda.com
                        </a>
                        , generate an Api Key and enter it in the following field.
                    </p>
                    <p>
                        <b>Make shure that you include Trading, Transaction and Balance !!!</b>
                    </p>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">API KEY</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            className={classes.spaceBottom}
                            value={valueApiKey}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <VpnKeyIcon color="primary" />
                                </InputAdornment>
                            }
                            labelWidth={60}
                            color="primary"
                        />
                        <Button variant="contained" onClick={handleSubmit} color="secondary" className={classes.spaceBottom}>
                            Submit
                        </Button>
                    </FormControl>
                </Fragment>
            )}
            <p className={classes.disclaimer}>
                The Api Key is stored exclusively in the LocalStorage of your currently used browser. If you delete the LocalStorage of your browser, or
                use another browser, you have to deposit the Api Key again, or if you forgot it, create a new one on{' '}
                <a href="https://web.bitpanda.com/apikey" target="_blank" rel="noopener noreferrer">
                    bitpanda.com
                </a>
                .
            </p>
        </div>
    );
};

export default ApiTokenForm;
