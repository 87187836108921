import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tipBotWrapper: {
        float: 'left',
        maxWidth: '65%',
        maxHeight: '50px',
    },
    tipBot: {
        transform: 'scale(.65)',
        transformOrigin: '0 0',
    },

    overline: {
        display: 'block',
        marginBottom: '0.25rem',
        lineHeight: 'normal',
    },
}));

const XrpTipBot = () => {
    const classes = useStyles();

    return (
        <div className={classes.tipBotWrapper}>
            <Typography variant="overline" className={classes.overline}>
                Support the Developer
            </Typography>
            <div className={classes.tipBot}>
                <a
                    amount="0.35"
                    size="275"
                    to="scurrilus"
                    network="twitter"
                    href="https://www.xrptipbot.com"
                    target="_blank"
                    stylesheet="https://www.untermstrichzahlich.de/xrptipbot/styles.css"
                >
                    xrpTipBot
                </a>
            </div>
        </div>
    );
};

export default XrpTipBot;
