import React, { useState, useEffect, useRef, Fragment, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core/';
import parse from 'html-react-parser';
import ApiTokenForm from '../ApiTokenForm/ApiTokenForm';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../contexts/DataContextProvider';

// Icon
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DataUsageIcon from '@material-ui/icons/DataUsage';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: '#fff',
    },
}));

const DialogModal = (props) => {
    const classes = useStyles();
    const { applicationData } = useContext(DataContext);
    const { data } = props;
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const usedIcon = () => {
        if (data.icon === 'HelpOutlineIcon') {
            return <HelpOutlineIcon />;
        } else if (data.icon === 'DataUsageIcon') {
            return <DataUsageIcon />;
        } else {
            return <VpnKeyIcon />;
        }
    };

    const descriptionElementRef = useRef(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;

            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        const apiToken = localStorage.getItem('apiToken');
        if (data.component === 'ApiTokenForm' && !apiToken) {
            setOpen(true);
        }
    }, [applicationData]);

    return (
        <Fragment>
            <IconButton aria-label="Api Token" onClick={handleClickOpen('paper')} className={classes.iconButton}>
                {usedIcon()}
            </IconButton>
            <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
                <DialogTitle id="scroll-dialog-title">{data.title}</DialogTitle>
                <DialogContent dividers={scroll === 'paper'} id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                    {data.html && parse(data.html)}
                    {data.component === 'ApiTokenForm' && <ApiTokenForm close={handleClose} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        <CancelIcon /> Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default DialogModal;
