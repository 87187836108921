import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
// import App from "./App";
import Header from './components/Navigation/Header';
import Wallets from './components/Wallets/Wallets';
import Footer from './components/Navigation/Footer';
import PullToRefresh from './components/PullToRefresh/PullToRefresh';

import * as serviceWorker from './serviceWorker';
import 'cryptocurrency-icons-font/dist/webfont/cryptocurrency-icons.css';
import DataContextProvider from './contexts/DataContextProvider';
import WalletsSummary from './components/Wallets/WalletsSummary';
import InvestChart from './components/Charts/InvestChart';
import ProfitSummaryChart from './components/Charts/ProfitSummaryChart';

// ReactDOM.render(<Header />, document.getElementById('headerNavigation'));
// ReactDOM.render(<Wallets />, document.getElementById('root'));
// ReactDOM.render(<Footer />, document.getElementById('footer_navigation'));

ReactDOM.render(
    <DataContextProvider>
        <Header />
        <PullToRefresh />
        <WalletsSummary />
        <Wallets />
        <InvestChart />
        <ProfitSummaryChart />
        <Footer />
    </DataContextProvider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
