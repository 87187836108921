import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../contexts/DataContextProvider';
import LinearProgress from '@material-ui/core/LinearProgress';

const PullToRefresh = () => {
    const scrollDistance = 100;
    const [progress, setProgress] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const { refreshData } = useContext(DataContext);

    const useStyles = makeStyles(() => ({
        linearProgress: {
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
        },
    }));

    window.ontouchstart = (event) => {
        const touchMoveDistance = event.changedTouches[0].clientY + scrollDistance;
        window.ontouchmove = (event) => {
            const calcScroll = 100 - (100 / scrollDistance) * (touchMoveDistance - event.changedTouches[0].clientY);
            calcScroll <= 100 && setProgress(calcScroll);
            if (window.scrollY === 0 && calcScroll > 1 && !refresh) {
                // show linarProgress
                setRefresh(true);
            }

            if (calcScroll > 100) {
                window.ontouchend = () => {
                    // refresh Data and reset PullToRefresh component
                    if (window.scrollY < 10) {
                        refreshData();
                        setRefresh(false);
                        setProgress(0);
                    }
                };
            }
        };

        window.ontouchend = () => {
            // reset PullToRefresh component
            setRefresh(false);
            setProgress(0);
        };
    };

    const classes = useStyles();
    return refresh && <LinearProgress variant="determinate" value={progress} className={classes.linearProgress} color="secondary" />;
};

export default PullToRefresh;
