import React, { Fragment, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DataContext } from '../../contexts/DataContextProvider';
import { Container, Paper, useMediaQuery, Grid, Typography } from '@material-ui/core';
import currencySign from '../../helpers/currencySign/currencySign';

// Charts
import ProfitChart from '../Charts/ProfitChart';
import ProfitSumChart from '../Charts/ProfitSumChart';
import InvestPiChart from '../Charts/InvestPiChart';
import ProfitPiChart from '../Charts/ProfitPiChart';
import PerformancePiChart from '../Charts/PerformancePiChart';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-138492283-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// For ProfitChart

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#c1fcfa',
        background: 'linear-gradient(180deg, #001a28 0, #123647)',
        border: '1px dashed rgba(193,252,250,0.75)',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    innerPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: '#c1fcfa',
        background: 'rgba(0,0,0,0.25)',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    item: {
        color: '#c1fcfa',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75em',
        },
    },
    topItems: {
        color: '#ffffff',
        paddingBottom: '0.5rem',
        fontSize: '1.1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    icon: {
        fontSize: '30vw',
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        color: 'rgba(195, 250, 250, 0.25)',
        [theme.breakpoints.down('sm')]: {
            fontSize: '30vw',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '8vw',
        },
    },
    overline: {
        fontWeight: 'bold',
        color: '#fff',
    },
    minus: {
        color: '#cc0000',
    },
    plus: {
        color: '#adff2f',
    },
}));

const Wallet = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { applicationData } = useContext(DataContext);
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    // console.log('applicationData', applicationData);

    return (
        <Fragment>
            <div className={classes.root}>
                <Container maxWidth="lg" disableGutters>
                    <Grid container spacing={isSm ? 1 : 2}>
                        {applicationData &&
                            applicationData.wallets.map((item) => {
                                const { name, price, balance, priceAverage, invest, profit, value, profitPCT, cryptoShort, fiatShort } = item;
                                // console.log('applicationData', applicationData);
                                return (
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Paper className={classes.paper}>
                                            <div className={`crypto-icon ${item.cryptoShort.toLowerCase()} ${classes.icon}`}></div>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}>
                                                    <Typography variant="h6" component="h3" className={classes.topItems}>
                                                        {name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6" component="h3" align="right" className={classes.topItems}>
                                                        {price} {currencySign(fiatShort)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Paper className={classes.innerPaper}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="overline" className={classes.overline}>
                                                            Balance
                                                        </Typography>
                                                        <Typography variant="body1" className={classes.item}>
                                                            {balance} {cryptoShort}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="overline" className={classes.overline}>
                                                            Price Average
                                                        </Typography>
                                                        <Typography variant="body1" className={classes.item}>
                                                            {priceAverage.toFixed(4)} {currencySign(fiatShort)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="overline" className={classes.overline}>
                                                            Invest
                                                        </Typography>
                                                        <Typography variant="body1" className={classes.item}>
                                                            {invest.toFixed(2)} {currencySign(fiatShort)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="overline" className={classes.overline}>
                                                            Value
                                                        </Typography>
                                                        <Typography variant="body1" className={classes.item}>
                                                            {value.toFixed(2)} {currencySign(fiatShort)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="overline" className={classes.overline}>
                                                            Profit
                                                        </Typography>
                                                        <Typography variant="body1" className={classes.item}>
                                                            {profit.toFixed(2)} {currencySign(fiatShort)}{' '}
                                                            <span className={profit < 0 ? classes.minus : classes.plus}>
                                                                ({profit > 0 && '+'}
                                                                {profitPCT.toFixed(2)} %)
                                                            </span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Paper>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Container>
            </div>
        </Fragment>
    );
};

export default Wallet;
